import React from "react"
import { groq } from "next-sanity"
import { Box, Flex } from "@chakra-ui/react"
import Layout from "@components/Layout/hoc/withCmsData"
import Helmet from "@components/Helmet"
import SideNav from "@components/SideNav"
import CmsContent from "@cms/CmsContent"
import { bp } from "@utils/MediaQueries"
import { usePreviewSubscription } from "@utils/sanity"

export const query = groq`*[_type == "page" && slug == $slug][0] {
  title,
  seoTitle,
  seoDescription,
  "openGraphImage": openGraphImage.asset->url,
  sideNav->,
  content[] {
    _type == "anchorNav" => {...},
    _type == "banner" => {...},
    _type == "bannerCarousel" => {...},
    _type == "cardSlider" => {...},
    _type == "contentBlock" => {...},
    _type == "ctaCards" => {...},
    _type == "downloads" => {
      ...,
      downloads[] {
        ...,
        "image": image.asset->url,
        "mobileImage": mobileImage.asset->url,
        "download": download.asset->url,
      },
    },
    _type == "faq" => @->,
    _type == "featureBlock" => {
      ...,
      featureItems[] {
        ...,
        "image": image.asset->url,
      }
    },
    _type == "hero" => {...},
    _type == "heroCarousel" => {...},
    _type == "imageGrid" => {...},
    _type == "legalContent" => {...},
    _type == "logoGrid" => {
      ...,
      logos[] {
        ...,
        "image": image.asset->url,
      }
    },
    _type == "newsletterForm" => @->,
    _type == "productSlider" => {
      _key,
      _type,
      header,
      products[]-> {
        productName,
        releaseDate,
        "slug": slug.current,
        sku,
        tags,
        price,
        "category": categories[]->name,
        "image": mainImage.asset->url,
        variants[]-> {
          ...
        },
      },
      cta {
        label,
        displayButton,
        internalLink,
        externalLink
      }
    },
    _type == "reference" => @->,
    _type == "spacer" => {...},
    _type == "textBlock" => {...},
    _type == "verticalDotSpacer" => {...},
    _type == "verticalSpace" => {...},
    _type == "youtubeVideo" => {...},
    "placeholderImage": *[_type == "siteConfig"].placeholderImage {
  	  "url": asset->url
	  },
  }
}`

// For FAQs, use: _type == "reference" => @->
// See FAQ model in faq.js for reason

const Page: any = ({ preview, pageData: staticData, slug }) => {
  const { data: liveData } = usePreviewSubscription(query, {
    params: { slug },
    initialData: staticData,
    enabled: true,
  })

  const pageData = liveData || staticData

  return (
    <Layout>
      <Helmet
        title={pageData?.seoTitle || pageData?.title || "SKB Cases"}
        description={pageData?.seoDescription}
        image={pageData?.openGraphImage}
      />
      {pageData?.sideNav ? (
        <Flex
          alignItems="flex-start"
          padding={bp("0 20px 100px", null, "100px 2p5col")}
          flexWrap={bp("wrap", null, "unset")}
          flexShrink="inherit"
        >
          <SideNav
            title={pageData?.sideNav?.title || ""}
            links={pageData?.sideNav?.links || []}
          />
          <Box w="100%" m={bp("0", null, "0 0 0 0p5col")}>
            <CmsContent content={pageData?.content || []} />
          </Box>
        </Flex>
      ) : (
        <CmsContent content={pageData?.content || []} />
      )}
    </Layout>
  )
}

export default Page
