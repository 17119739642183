import React from "react"
import { Box, BoxProps, css, Text, useTheme } from "@chakra-ui/react"
import Link from "@components/Link"
import colors from "@constants/colors"
import { bp, LaptopUpOnly } from "@utils/MediaQueries"
import { formatUrl } from "@utils/urls"
import { useRouter } from "next/router"

export interface SideNavProps extends Omit<BoxProps, "children" | "title"> {
  links: Array<{
    slug: string
    title: string
  }>
  title?: string
}

const SideNav: React.FC<SideNavProps> = ({ links, title, ...rest }) => {
  const theme = useTheme()
  const styles = css({
    fontFamily: "Avenir",
    p: {
      fontSize: "23px",
      fontWeight: "800",
      paddingBottom: "1rem",
    },
    a: {
      fontWeight: "800",
    },
  })(theme)
  const router = useRouter()

  return (
    <Box
      display="flex"
      flexDir={bp("row", null, "column")}
      justifyContent="flex-start"
      width={bp("100%", null, "calc(3/16 *100vw)")}
      minWidth="300px"
      css={styles}
      overflow="auto"
      {...rest}
    >
      <LaptopUpOnly>
        <Text>{title}</Text>
      </LaptopUpOnly>
      {links &&
        links.map(({ slug, title }, idx) => {
          const slug_text = slug.includes("/articles") ? slug : "/" + slug
          return (
            <Box
              key={idx}
              flexGrow={0}
              flexShrink={0}
              alignItems="center"
              height={bp("68px", null, "68px")}
              display="flex"
              px={bp("1rem", null, "1rem")}
              borderTop="1px solid"
              borderColor={colors.midLight}
              _last={bp(null, null, {
                borderBottom: "1px solid",
                borderColor: colors.midLight,
              })}
              bg={router.asPath === slug_text ? colors.light : "white"}
            >
              <Link href={formatUrl(slug)}>{title}</Link>
            </Box>
          )
        })}
    </Box>
  )
}

export default SideNav
