import { GetStaticPaths, GetStaticProps } from "next"
import Page, { query } from "@views/Page"
import { getClient } from "@utils/sanity"

export default Page

export const getStaticPaths: GetStaticPaths = async () => {
  const routes = await getClient()
    .fetch(`*[_type == "page" && slug != "/" && slug != "careers"] {
      "params": { "slug": slug }
    }`)

  return {
    paths: routes || null,
    fallback: false,
  }
}

export const getStaticProps: GetStaticProps = async ({
  params = {},
  preview = false,
}) => {
  const { slug } = params
  const pageData = await getClient(preview).fetch(query, {
    slug,
  })

  return {
    props: { preview, pageData, slug },
  }
}
